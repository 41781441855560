import axios from 'axios';

class UploadFileService {
    upload(file, applicationId, year, file_type, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);       
        formData.append('applicationId', applicationId);
        formData.append('year', year);
        formData.append('file_type', file_type);

        return axios.post("/api/sixth-form-application-store-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    getFiles($applicationId, $year) {
        return axios.get("/api/sixth-form-application-get-files/"+$applicationId+"/"+$year);
    }
}

export default new UploadFileService();